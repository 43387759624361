
  .pro-sidebar .pro-menu {
    padding: 0;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    padding: 0;
  }
  #header .pro-sidebar {
    width: 100%;
    min-width: 230px;
  }
  #header .pro-sidebar .pro-sidebar-inner {
    padding: 0 0 0 10px;
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  #header .pro-sidebar-inner {
    background-color: white;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    padding: 10px;
    color: #000;  
    font-weight: bold;
    margin: 0;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #868b90;
    margin: 10px 0 0 0;
    padding: 6px 0;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item:hover {
    background-color: #f9fafb;
    color: black;
    border-radius: 12px 0 0 12px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: transparent;
    color: #868b90;

    border-radius: 3px;
    margin: 0;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #868b90;
    
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-menu-item.active .pro-inner-item .pro-item-content,   #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-menu-item.active .pro-icon-wrapper .pro-icon {
    
    color: #000;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header{
    border-bottom: none;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer{
    border-top: none;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-menu-item.active {
    background-color: #f9fafb;
    border-radius: 12px 0 0 12px;
  }
  #header .logo {
    padding: 20px;
  }
  
  @media only screen and (max-width: 720px) {
    html {
      overflow: hidden;
    }
  }